import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import axios from "axios";

export default function MemeInternship() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [interns, setInterns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({
    totalTasks: 0,
    executedTasks: 0,
    totalMemes: 0,
    totalInterns: 0,
  });
  const [activeTab, setActiveTab] = useState("intern"); 
  const [newTask, setNewTask] = useState({
    name: '',
    description: '',
    type: '',
    levelAmount: 0,
    active: true,
    condition0: 0,
    reward0: 0,
    condition1: 0,
    reward1: 0,
    condition2: 0,
    reward2: 0,
    condition3: 0,
    reward3: 0,
    condition4: 0,
    reward4: 0,
  });

  useEffect(() => {
    if (!user || !user.token) {
      navigate("/login");
    } else {
      fetchTasks();
    }
  }, [user, navigate]);

  const fetchTasks = async () => {
    try {
      const response = await axios.get(
        "https://api.mymeme.exchange/admin/internship",
        {
          headers: { Authorization: `Bearer ${user.token}` },
        },
      );
      setTasks(response.data.tasks);
      setInterns(response.data.interns);
      setStats({
        totalTasks: response.data.totalTasks,
        executedTasks: response.data.executedTasks,
        totalMemes: response.data.totalMemes,
        totalInterns: response.data.totalInterns,
      });
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch tasks");
      setLoading(false);
    }
  };

  if (!user || !user.token) {
    return null;
  }


  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewTask(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        "https://api.mymeme.exchange/admin/internship-tasks",
        newTask,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      if (response.data) {
        fetchTasks();
        setNewTask({
          name: '',
          description: '',
          type: '',
          levelAmount: 0,
          active: true,
          condition0: 0,
          reward0: 0,
          condition1: 0,
          reward1: 0,
          condition2: 0,
          reward2: 0,
          condition3: 0,
          reward3: 0,
          condition4: 0,
          reward4: 0,
        });
      }
    } catch (err) {
      setError("Failed to create new task");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="space-y-8">
      {/* First segment: 4 columns of cards */}
      <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Total Interns</h2>
          <p className="text-3xl font-bold">{stats.totalInterns}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Total Tasks</h2>
          <p className="text-3xl font-bold">{stats.totalTasks}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Executed Tasks</h2>
          <p className="text-3xl font-bold">{stats.executedTasks}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Total MEME</h2>
          <p className="text-3xl font-bold">{stats.totalMemes}</p>
        </div>
      </div>

      {/* Tab bar */}
      <div className="mb-4">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex">
            <button
              className={`${
                activeTab === "intern"
                  ? "border-indigo-500 text-indigo-600"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
              onClick={() => setActiveTab("intern")}
            >
              Interns
            </button>
            <button
              className={`${
                activeTab === "task"
                  ? "border-indigo-500 text-indigo-600"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ml-8`}
              onClick={() => setActiveTab("task")}
            >
              Tasks
            </button>
          </nav>
        </div>
      </div>

      {/* Intern table */}
      {activeTab === "intern" && interns && interns.length > 0 && (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left">ID</th>
                <th className="px-4 py-2 text-left">Username</th>
                <th className="px-4 py-2 text-left">MEME</th>
                <th className="px-4 py-2 text-left">Created At</th>
              </tr>
            </thead>
            <tbody>
              {interns.map((intern) => (
                <tr
                  key={intern.id}
                  onClick={() => navigate(`/meme/interns/${intern.id}`)}
                  className="cursor-pointer hover:bg-gray-100"
                >
                  <td className="border px-4 py-2">{intern.id}</td>
                  <td className="border px-4 py-2">
                    {intern.twitterUsername}
                  </td>
                  <td className="border px-4 py-2">{intern.memeBalance}</td>
                  <td className="border px-4 py-2">
                    {new Date(intern.createdAt).toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Task table */}
      {activeTab === "task" && tasks && tasks.length > 0 && (
        <>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left">ID</th>
                  <th className="px-4 py-2 text-left">Name</th>
                  <th className="px-4 py-2 text-left">Active</th>
                  <th className="px-4 py-2 text-left">Level</th>
                  <th className="px-4 py-2 text-left">Condition</th>
                  <th className="px-4 py-2 text-left">Reward</th>
                </tr>
              </thead>
              <tbody>
                {tasks.map((task) => (
                  <tr
                    key={task.id}
                    onClick={() =>
                      navigate(`/meme/internship-tasks/${task.id}`)}
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    <td className="border px-4 py-2">{task.id}</td>
                    <td className="border px-4 py-2">{task.name}</td>
                    <td className="border px-4 py-2">
                      {task.active ? "Yes" : "No"}
                    </td>
                    <td className="border px-4 py-2">{task.level}</td>
                    <td className="border px-4 py-2">
                      {task.level > 0
                        ? (
                          <>
                            {[...Array(task.level + 1)].map((_, index) => (
                              <div key={index}>
                                Level {index}: {task[`level${index}Amount`]}
                              </div>
                            ))}
                          </>
                        )
                        : (
                          task.level0Amount
                        )}
                    </td>
                    <td className="border px-4 py-2">
                      {task.level > 0
                        ? (
                          <>
                            {[...Array(task.level + 1)].map((_, index) => (
                              <div key={index}>
                                Level {index}: {task[`level${index}Reward`]}
                              </div>
                            ))}
                          </>
                        )
                        : (
                          task.level0Reward
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Form to create a new task */}
          <h2 className="text-xl font-semibold mb-4 mt-8">Create New Task</h2>
          <div className="w-full bg-white shadow-lg rounded-lg p-6">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-semibold text-gray-700 mb-1"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={newTask.name}
                  onChange={handleInputChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out"
                />
              </div>
              <div>
                <label
                  htmlFor="description"
                  className="block text-sm font-semibold text-gray-700 mb-1"
                >
                  Description
                </label>
                <textarea
                  id="description"
                  name="description"
                  value={newTask.description}
                  onChange={handleInputChange}
                  rows="4"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out resize-none"
                >
                </textarea>
              </div>
              <div>
                <label htmlFor="levelAmount" className="block text-sm font-semibold text-gray-700 mb-1">
                  Level Amount
                </label>
                <input
                  type="number"
                  id="levelAmount"
                  name="levelAmount"
                  value={newTask.levelAmount}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out"
                />
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="active"
                  name="active"
                  checked={newTask.active}
                  onChange={handleInputChange}
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label htmlFor="active" className="ml-2 block text-sm text-gray-900">
                  Active
                </label>
              </div>
              {[0, 1, 2, 3, 4].map((level) => (
                <div key={level} className="grid grid-cols-2 gap-4">
                  <div>
                    <label htmlFor={`condition${level}`} className="block text-sm font-semibold text-gray-700 mb-1">
                      Condition {level}
                    </label>
                    <input
                      type="number"
                      id={`condition${level}`}
                      name={`condition${level}`}
                      value={newTask[`condition${level}`]}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out"
                    />
                  </div>
                  <div>
                    <label htmlFor={`reward${level}`} className="block text-sm font-semibold text-gray-700 mb-1">
                      Reward {level}
                    </label>
                    <input
                      type="number"
                      id={`reward${level}`}
                      name={`reward${level}`}
                      value={newTask[`reward${level}`]}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out"
                    />
                  </div>
                </div>
              ))}
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
                >
                  Create Task
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
}
