import React, { useEffect, useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import axios from 'axios';

export default function MemeWithdrawal() {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [withdrawalData, setWithdrawalData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user || !user.token) {
      navigate('/login');
    } else {
      fetchWithdrawalDetails();
    }
  }, [user, navigate, id]);

  const fetchWithdrawalDetails = async () => {
    try {
      const response = await axios.get(`https://api.mymeme.exchange/admin/withdrawals/${id}`, {
        headers: { Authorization: `Bearer ${user.token}` }
      });
      console.log(response.data);
      setWithdrawalData(response.data.withdrawal);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch withdrawal details');
      setLoading(false);
    }
  };

  if (!user || !user.token) {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!withdrawalData) {
    return <div>No withdrawal data available</div>;
  }

  return (
    <div className="space-y-8">
      <h1 className="text-2xl font-bold mb-4">Withdrawal Details</h1>
      
      {/* Withdrawal summary */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-4">Withdrawal Summary</h2>
        <div className="grid grid-cols-2 gap-4">
          <p><strong>ID:</strong> {withdrawalData.id}</p>
          <p><strong>User ID:</strong> {withdrawalData.userId}</p>
          <p><strong>Amount:</strong> ${withdrawalData.amount > 0 ? (withdrawalData.amount / 100).toFixed(2) : '0.00'}</p>
          <p><strong>Status:</strong> {withdrawalData.status}</p>
          <p><strong>Created At:</strong> {new Date(withdrawalData.createdAt).toLocaleString()}</p>
          <p><strong>Updated At:</strong> {new Date(withdrawalData.updatedAt).toLocaleString()}</p>
        </div>
      </div>

      {/* Additional withdrawal details can be added here */}
    </div>
  );
}
