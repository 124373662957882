import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { UserProvider } from './context/UserContext';
import MainLayout from './components/MainLayout';
import MemeLayout from './components/MemeLayout';
import WalletLayout from './components/WalletLayout';

import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';

import MemeDashboard from './pages/meme/Dashboard';
import MemeCharacters from './pages/meme/Characters';
import MemeCharacter from './pages/meme/Character';
import MemePremarks from './pages/meme/Premarks';
import MemePremark from './pages/meme/Premark';
import MemePremarkManagers from './pages/meme/PremarkManagers';
import MemePremarkManager from './pages/meme/PremarkManager';
import MemeCoins from './pages/meme/Coins';
import MemeCoin from './pages/meme/Coin';
import MemeDeposits from './pages/meme/Deposits';
import MemeDeposit from './pages/meme/Deposit';
import MemeWallets from './pages/meme/Wallets';
import MemeWallet from './pages/meme/Wallet';
import MemeUsers from './pages/meme/Users';
import MemeUser from './pages/meme/User';
import MemePosts from './pages/meme/Posts';
import MemePost from './pages/meme/Post';
import MemeWithdrawals from './pages/meme/Withdrawals';
import MemeWithdrawal from './pages/meme/Withdrawal';

import MemeIntern from './pages/meme/Intern';
import MemeInternship from './pages/meme/Internship';
import MemeInternshipTask from './pages/meme/InternshipTask';



import WalletDashboard from './pages/wallet/Dashboard';


import { Buffer } from 'buffer';
window.Buffer = Buffer;

function App() {
  return (
    <UserProvider>
      <Router>
          <Routes>
            <Route path="/" element={<MemeLayout><Home /></MemeLayout>} />
            <Route path="/dashboard" element={<MemeLayout><Dashboard /></MemeLayout>} />
            <Route path="/login" element={<MemeLayout><Login /></MemeLayout>} />
            
            <Route path="/meme/dashboard" element={<MemeLayout><MemeDashboard /></MemeLayout>} />
            <Route path="/meme/internship" element={<MemeLayout><MemeInternship /></MemeLayout>} />
            <Route path="/meme/internship-tasks/:id" element={<MemeLayout><MemeInternshipTask /></MemeLayout>} />
            <Route path="/meme/interns/:id" element={<MemeLayout><MemeIntern /></MemeLayout>} />
            <Route path="/meme/characters" element={<MemeLayout><MemeCharacters /></MemeLayout>} />
            <Route path="/meme/characters/:id" element={<MemeLayout><MemeCharacter /></MemeLayout>} />  
            <Route path="/meme/premark-managers" element={<MemeLayout><MemePremarkManagers /></MemeLayout>} />
            <Route path="/meme/premark-managers/:id" element={<MemeLayout><MemePremarkManager /></MemeLayout>} />                        
            <Route path="/meme/coins" element={<MemeLayout><MemeCoins /></MemeLayout>} />
            <Route path="/meme/coins/:id" element={<MemeLayout><MemeCoin /></MemeLayout>} />
            <Route path="/meme/deposits" element={<MemeLayout><MemeDeposits /></MemeLayout>} />
            <Route path="/meme/deposits/:id" element={<MemeLayout><MemeDeposit /></MemeLayout>} />            
            <Route path="/meme/users" element={<MemeLayout><MemeUsers /></MemeLayout>} />
            <Route path="/meme/users/:id" element={<MemeLayout><MemeUser /></MemeLayout>} />
            <Route path="/meme/posts" element={<MemeLayout><MemePosts /></MemeLayout>} />
            <Route path="/meme/posts/:id" element={<MemeLayout><MemePost /></MemeLayout>} />   
            <Route path="/meme/premarks" element={<MemeLayout><MemePremarks /></MemeLayout>} />
            <Route path="/meme/premarks/:id" element={<MemeLayout><MemePremark /></MemeLayout>} />                     
            <Route path="/meme/wallets" element={<MemeLayout><MemeWallets /></MemeLayout>} />
            <Route path="/meme/wallets/:id" element={<MemeLayout><MemeWallet /></MemeLayout>} />  
            <Route path="/meme/withdrawals" element={<MemeLayout><MemeWithdrawals /></MemeLayout>} />
            <Route path="/meme/withdrawals/:id" element={<MemeLayout><MemeWithdrawal /></MemeLayout>} />                

            <Route path="/wallet/dashboard" element={<WalletLayout><WalletDashboard /></WalletLayout>} />

          </Routes>     
      </Router>
    </UserProvider>
  );
}

export default App;