import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import axios from 'axios';

export default function MemeWithdrawals() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [withdrawals, setWithdrawals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({
    totalWithdrawals: 0,
    newWithdrawals: 0,
    totalAmount: 0,
    pendingWithdrawals: 0
  });

  useEffect(() => {
    if (!user || !user.token) {
      navigate('/login');
    } else {
      fetchWithdrawals();
    }
  }, [user, navigate]);

  const fetchWithdrawals = async () => {
    try {
      const response = await axios.get('https://api.mymeme.exchange/admin/withdrawals', {
        headers: { Authorization: `Bearer ${user.token}` }
      });
      console.log(response.data);
      setWithdrawals(response.data.withdrawals);
      setStats({
        totalWithdrawals: response.data.totalWithdrawals,
        newWithdrawals: response.data.newWithdrawals,
        totalAmount: response.data.totalAmount,
        pendingWithdrawals: response.data.pendingWithdrawals
      });
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch withdrawals');
      setLoading(false);
    }
  };

  if (!user || !user.token) {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="space-y-8">
      {/* First segment: 4 columns of cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Total Withdrawals</h2>
          <p className="text-3xl font-bold">{stats.totalWithdrawals}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">New Withdrawals (24H)</h2>
          <p className="text-3xl font-bold">{stats.newWithdrawals}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Total Amount</h2>
          <p className="text-3xl font-bold">${stats.totalAmount > 0 ? (stats.totalAmount / 100).toFixed(2) : '0.00'}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Pending Withdrawals</h2>
          <p className="text-3xl font-bold">{stats.pendingWithdrawals}</p>
        </div>
      </div>

      {/* Second segment: table of withdrawals */}
      {withdrawals && withdrawals.length > 0 && (
        <div>
          <h2 className="text-xl font-semibold mb-2">Latest Withdrawals</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left">ID</th>
                  <th className="px-4 py-2 text-left">User ID</th>
                  <th className="px-4 py-2 text-left">Amount</th>
                  <th className="px-4 py-2 text-left">Status</th>
                  <th className="px-4 py-2 text-left">Created At</th>
                </tr>
              </thead>
              <tbody>
                {withdrawals.map((withdrawal) => (
                  <tr key={withdrawal.id} onClick={() => navigate(`/meme/withdrawals/${withdrawal.id}`)} className="cursor-pointer hover:bg-gray-100">
                    <td className="border px-4 py-2">{withdrawal.id}</td>
                    <td className="border px-4 py-2">{withdrawal.userId}</td>
                    <td className="border px-4 py-2">${withdrawal.amount > 0 ? (withdrawal.amount / 100).toFixed(2) : '0.00'}</td>
                    <td className="border px-4 py-2">{withdrawal.status}</td>
                    <td className="border px-4 py-2">{new Date(withdrawal.createdAt).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
