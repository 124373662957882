import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import axios from "axios";

export default function MemeCharacter() {
  const [activeTab, setActiveTab] = useState("about"); // Added state for active tab
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [character, setCharacter] = useState(null);
  const [coins, setCoins] = useState(null);
  const [posts, setPosts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchCharacterDetails = useCallback(async () => {
    if (!user || !user.token) return;

    try {
      const response = await axios.get(
        `https://api.mymeme.exchange/admin/characters/${id}`,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        },
      );
      setCharacter(response.data.character);
      setCoins(response.data.coins);
      setPosts(response.data.posts);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch character details");
      setLoading(false);
    }
  }, [id, user]);

  const handleCreateCoin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData(e.target);

      const response = await axios.post(
        "https://api.mymeme.exchange/admin/character-coins",
        formData,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "multipart/form-data",
          },
        },
      );

      if (response.data) {
        fetchCharacterDetails();
        e.target.reset();
      }
    } catch (err) {
      setError("Failed to create new coin");
    } finally {
      setLoading(false);
    }
  };

  const handleCreatePost = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData(e.target);

      const response = await axios.post(
        "https://api.mymeme.exchange/admin/character-posts",
        formData,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "multipart/form-data",
          },
        },
      );

      if (response.data) {
        fetchCharacterDetails();
        e.target.reset();
      }
    } catch (err) {
      setError("Failed to create new post");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!user || !user.token) {
      navigate("/login");
    } else {
      fetchCharacterDetails();
    }
  }, [user, navigate, fetchCharacterDetails]);

  if (!user || !user.token) {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!character) {
    return <div>No character data available</div>;
  }

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setCharacter((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));
  };

  const handleUpdateCharacter = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      Object.keys(character).forEach((key) => {
        formData.append(key, character[key]);
      });

      const response = await axios.put(
        `https://api.mymeme.exchange/admin/characters/${character.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "multipart/form-data",
          },
        },
      );

      if (response.data) {
        fetchCharacterDetails();
      }

      setLoading(false);
    } catch (err) {
      setError("Failed to update character");
      setLoading(false);
    }
  };

  return (
    <div className="space-y-8">
      <h1 className="text-2xl font-bold mb-4">
        {character.name}
      </h1>

      <div className="flex flex-col space-y-8">
        <div className="flex flex-row space-x-4">
          <button
            className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-medium py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-200"
            onClick={() => setActiveTab("about")}
          >
            About
          </button>
          <button
            className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-medium py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-200"
            onClick={() => setActiveTab("post")}
          >
            Post
          </button>
          <button
            className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-medium py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-200"
            onClick={() => setActiveTab("coin")}
          >
            Coin
          </button>
        </div>
        <div>
          {activeTab === "about" && (
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              <div className="w-full md:col-span-1">
                <img
                  src={character.imageUrl}
                  alt={character.name}
                  className="w-full h-auto rounded-lg mb-4"
                />
              </div>
              <div className="w-full md:col-span-3 bg-white shadow-lg rounded-lg p-6">
                <form onSubmit={handleUpdateCharacter} className="space-y-6">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-semibold text-gray-700 mb-1"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={character.name}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="description"
                      className="block text-sm font-semibold text-gray-700 mb-1"
                    >
                      Description
                    </label>
                    <textarea
                      id="description"
                      name="description"
                      value={character.description}
                      onChange={handleInputChange}
                      rows="4"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out resize-none"
                    >
                    </textarea>
                  </div>
                  <div>
                    <label
                      htmlFor="symbol"
                      className="block text-sm font-semibold text-gray-700 mb-1"
                    >
                      Symbol
                    </label>
                    <input
                      type="text"
                      id="symbol"
                      name="symbol"
                      value={character.symbol || ""}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="telegramChannelId"
                      className="block text-sm font-semibold text-gray-700 mb-1"
                    >
                      Telegram Channel Id
                    </label>
                    <input
                      type="text"
                      id="telegramChannelId"
                      name="telegramChannelId"
                      value={character.telegramChannelId || ""}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out"
                    />
                  </div>                  
                  <div>
                    <label
                      htmlFor="newsKeyword"
                      className="block text-sm font-semibold text-gray-700 mb-1"
                    >
                      News Keyword
                    </label>
                    <input
                      type="text"
                      id="newsKeyword"
                      name="newsKeyword"
                      value={character.newsKeyword || ""}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="image"
                      className="block text-sm font-semibold text-gray-700 mb-1"
                    >
                      Image
                    </label>
                    <input
                      type="file"
                      id="image"
                      name="image"
                      onChange={handleInputChange}
                      accept="image/*"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
                    />
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
                    >
                      Update Character
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
          {activeTab === "post" && (
            <div>
              <div className="flex">
                <div className="w-1/3 pr-4">
                  <div className="w-full bg-white shadow-lg rounded-lg p-6">
                    <form onSubmit={handleCreatePost} className="space-y-6">
                      <div>
                        <label
                          htmlFor="text"
                          className="block text-sm font-semibold text-gray-700 mb-1"
                        >
                          Text
                        </label>
                        <textarea
                          id="text"
                          name="text"
                          required
                          rows="4"
                          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out resize-none"
                        >
                        </textarea>
                      </div>
                      <div>
                        <label
                          htmlFor="image"
                          className="block text-sm font-semibold text-gray-700 mb-1"
                        >
                          Image
                        </label>
                        <input
                          type="file"
                          id="image"
                          name="image"
                          accept="image/*"
                          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="coinId"
                          className="block text-sm font-semibold text-gray-700 mb-1"
                        >
                          Coin ID
                        </label>
                        <input
                          type="number"
                          id="coinId"
                          name="coinId"
                          required
                          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out"
                        />
                      </div>
                      <input
                        type="hidden"
                        name="characterId"
                        value={character.id}
                      />
                      <div>
                        <button
                          type="submit"
                          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
                        >
                          Create Post
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="w-2/3 pl-4">
                  {posts && posts.length > 0 && (
                    <div className="overflow-x-auto">
                      <table className="min-w-full bg-white">
                        <thead>
                          <tr>
                            <th className="px-4 py-2 text-left">ID</th>
                            <th className="px-4 py-2 text-left">Content</th>
                            <th className="px-4 py-2 text-left">Created At</th>
                          </tr>
                        </thead>
                        <tbody>
                          {posts.map((post) => (
                            <tr key={post.id} onClick={() => navigate(`/meme/posts/${post.id}`)} className="cursor-pointer hover:bg-gray-100">
                              <td className="border px-4 py-2">{post.id}</td>
                              <td className="border px-4 py-2">{post.text}</td>
                              <td className="border px-4 py-2">
                                {new Date(post.createdAt).toLocaleString()}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {activeTab === "coin" && (
            <div className="flex">
              <div className="w-1/3 pr-4">
                <div className="w-full bg-white shadow-lg rounded-lg p-6">
                  <form onSubmit={handleCreateCoin} className="space-y-6">
                    <div>
                      <label
                        htmlFor="name"
                        className="block text-sm font-semibold text-gray-700 mb-1"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        required
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="symbol"
                        className="block text-sm font-semibold text-gray-700 mb-1"
                      >
                        Symbol
                      </label>
                      <input
                        type="text"
                        id="symbol"
                        name="symbol"
                        required
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="level"
                        className="block text-sm font-semibold text-gray-700 mb-1"
                      >
                        Level
                      </label>
                      <input
                        type="number"
                        id="level"
                        name="level"
                        required
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="description"
                        className="block text-sm font-semibold text-gray-700 mb-1"
                      >
                        Description
                      </label>
                      <textarea
                        id="description"
                        name="description"
                        rows="3"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out"
                      >
                      </textarea>
                    </div>
                    <input
                      type="hidden"
                      name="characterId"
                      value={character.id}
                    />
                    <div>
                      <label
                        htmlFor="icon"
                        className="block text-sm font-semibold text-gray-700 mb-1"
                      >
                        Icon
                      </label>
                      <input
                        type="file"
                        id="icon"
                        name="icon"
                        accept="image/*"
                        required
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
                      />
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
                      >
                        Create Coin
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="w-2/3 pl-4">
                {coins && coins.length > 0 && (
                  <div className="overflow-x-auto">
                    <table className="min-w-full bg-white">
                      <thead>
                        <tr>
                          <th className="px-4 py-2 text-left">ID</th>
                          <th className="px-4 py-2 text-left">Name</th>
                          <th className="px-4 py-2 text-left">Reserve</th>
                          <th className="px-4 py-2 text-left">Progress</th>
                          <th className="px-4 py-2 text-left">Phase</th>
                          <th className="px-4 py-2 text-left">Created At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {coins.map((coin) => (
                          <tr
                            key={coin.id}
                            onClick={() => navigate(`/meme/coins/${coin.id}`)}
                            className="cursor-pointer hover:bg-gray-100"
                          >
                            <td className="border px-4 py-2">{coin.id}</td>
                            <td className="border px-4 py-2">{coin.name}</td>
                            <td className="border px-4 py-2">{coin.reserve}</td>
                            <td className="border px-4 py-2">
                              {coin.supply > 0
                                ? ((coin.supply / 1000000000) * 100).toFixed(2) +
                                  "%"
                                : "0.00%"}
                            </td>
                            <td className="border px-4 py-2">{coin.phase}</td>
                            <td className="border px-4 py-2">
                              {new Date(coin.createdAt).toLocaleString()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
