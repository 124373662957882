import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import axios from 'axios';

export default function MemeUsers() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({
    totalUsers: 0,
    newUsers: 0,
    totalDollar: 0,
    totalMeme: 0
  });
  const [searchTerm, setSearchTerm] = useState(''); // Add searchTerm state
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' }); // Add sortConfig state
  const [filteredUsers, setFilteredUsers] = useState([]); // Add filteredUsers state

  useEffect(() => {
    if (!user || !user.token) {
      navigate('/login');
    } else {
      fetchUsers();
    }
  }, [user, navigate]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://api.mymeme.exchange/admin/users', {
        headers: { Authorization: `Bearer ${user.token}` }
      });
      setUsers(response.data.users);
      setFilteredUsers(response.data.users);
      setStats({
        totalUsers: response.data.totalUsers,
        newUsers: response.data.newUsers,
        totalDollar: response.data.totalDollar,
        totalMeme: response.data.totalMeme
      });
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch users');
      setLoading(false);
    }
  };

  useEffect(() => {
    const filtered = users.filter((user) =>
      user.telegramUsername.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filtered);
    console.log('Users:', users);
    console.log('Filtered Users:', filtered);
  }, [users, searchTerm]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    let sortedUsers = [...filteredUsers];
    if (sortConfig.key) {
      sortedUsers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    setFilteredUsers(sortedUsers);
  }, [sortConfig]);

  if (!user || !user.token) {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="space-y-8">
      {/* First segment: 4 columns of cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Total Users</h2>
          <p className="text-3xl font-bold">{stats.totalUsers}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">New Users (24H)</h2>
          <p className="text-3xl font-bold">{stats.newUsers}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Dollar Balance</h2>
          <p className="text-3xl font-bold">${stats.totalDollar > 0 ? (stats.totalDollar / 100).toFixed(2) : '0.00'}</p>
        </div>
        {/* <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">MEME Balance</h2>
          <p className="text-3xl font-bold">{stats.totalMeme}</p>
        </div> */}
      </div>

      {/* Second segment: table of users */}
      <div>
        <input
          type="text"
          placeholder="Search by Telegram Username..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="mb-4 p-2 border rounded"
        />
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left">ID</th>
                <th className="px-4 py-2 text-left cursor-pointer" onClick={() => requestSort('telegramUsername')}>
                  Telegram Username {sortConfig.key === 'telegramUsername' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </th>
                <th className="px-4 py-2 text-left">Telegram ID</th>
                <th className="px-4 py-2 text-left cursor-pointer" onClick={() => requestSort('centBalance')}>
                  Balance {sortConfig.key === 'centBalance' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </th>
                <th className="px-4 py-2 text-left cursor-pointer" onClick={() => requestSort('createdAt')}>
                  Created At {sortConfig.key === 'createdAt' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user) => (
                <tr key={user.id} onClick={() => navigate(`/meme/users/${user.id}`)} className="cursor-pointer hover:bg-gray-100">
                  <td className="border px-4 py-2">{user.id}</td>
                  <td className="border px-4 py-2">{user.telegramUsername}</td>
                  <td className="border px-4 py-2">{user.telegramId}</td>
                  <td className="border px-4 py-2">${user.centBalance > 0 ? (user.centBalance / 100).toFixed(2) : '0.00'}</td>
                  <td className="border px-4 py-2">{new Date(user.createdAt).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
