import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

export default function Login() {
  const [token, setToken] = useState('');
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const { setUser } = userContext;

  const handleLogin = async (e) => {
    e.preventDefault();
    // Implement your login logic here
    setUser({ token });
    navigate('/dashboard');
  };

  return (
    <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-6">
      <h1 className="text-2xl font-bold mb-4">Login</h1>
      <form onSubmit={handleLogin}>
        <input
          type="text"
          value={token}
          onChange={(e) => setToken(e.target.value)}
          placeholder="Enter your token"
          className="w-full p-2 border rounded mb-4"
        />
        <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded">
          Login
        </button>
      </form>
    </div>
  );
}