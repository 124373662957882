

import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';



export default function WalletDashboard() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [mnemonic, setMnemonic] = useState('');
  const [addresses, setAddresses] = useState({
    solana: '',
    bitcoin: '',
    ton: '',
  });

  useEffect(() => {
    if (!user || !user.token) {
      navigate('/login');
    }
  }, [user, navigate]);



  if (!user || !user.token) {
    return null;
  }

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Wallet Dashboard</h1>

    </div>
  );
}