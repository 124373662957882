import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import axios from 'axios';

export default function MemePremarks() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({
    totalUsers: 0,
    newUsers: 0,
    totalDollar: 0,
    totalMeme: 0
  });

  useEffect(() => {
    if (!user || !user.token) {
      navigate('/login');
    } else {
      fetchUsers();
    }
  }, [user, navigate]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://api.mymeme.exchange/admin/users', {
        headers: { Authorization: `Bearer ${user.token}` }
      });
      console.log(response.data)
      setUsers(response.data.users);
      setStats({
        totalUsers: response.data.totalUsers,
        newUsers: response.data.newUsers,
        totalDollar: response.data.totalDollar,
        totalMeme: response.data.totalMeme
      });
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch users');
      setLoading(false);
    }
  };

  if (!user || !user.token) {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="space-y-8">
      {/* First segment: 4 columns of cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Total Users</h2>
          <p className="text-3xl font-bold">{stats.totalUsers}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">New Users (24H)</h2>
          <p className="text-3xl font-bold">{stats.newUsers}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Dollar Balance</h2>
          <p className="text-3xl font-bold">${stats.totalDollar > 0 ? (stats.totalDollar / 100).toFixed(2) : '0.00'}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">MEME Balance</h2>
          <p className="text-3xl font-bold">{stats.totalMeme}</p>
        </div>
      </div>

      {/* Second segment: table of users */}
      <div>
        <h2 className="text-xl font-semibold mb-2">All Users</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left">ID</th>
                <th className="px-4 py-2 text-left">Telegram Username</th>
                <th className="px-4 py-2 text-left">Telegram ID</th>
                <th className="px-4 py-2 text-left">Cent Balance</th>
                <th className="px-4 py-2 text-left">Meme Balance</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id} onClick={() => navigate(`/meme/users/${user.id}`)} className="cursor-pointer hover:bg-gray-100">
                  <td className="border px-4 py-2">{user.id}</td>
                  <td className="border px-4 py-2">{user.telegramUsername}</td>
                  <td className="border px-4 py-2">{user.telegramId}</td>
                  <td className="border px-4 py-2">${user.centBalance > 0 ? (user.centBalance / 100).toFixed(2) : '0.00'}</td>
                  <td className="border px-4 py-2">{user.memeBalance}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}