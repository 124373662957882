import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import axios from 'axios';

export default function MemeDeposits() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [deposits, setDeposits] = useState([]);
  const [filteredDeposits, setFilteredDeposits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({
    totalDeposits: 0,
    newDeposits: 0,
    totalAmount: 0,
    pendingDeposits: 0
  });
  const [filter, setFilter] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  useEffect(() => {
    if (!user || !user.token) {
      navigate('/login');
    } else {
      fetchDeposits();
    }
  }, [user, navigate]);

  useEffect(() => {
    const filtered = deposits.filter(deposit =>
      deposit.user.telegramUsername.toLowerCase().includes(filter.toLowerCase())
    );
    setFilteredDeposits(filtered);
  }, [deposits, filter]);

  const fetchDeposits = async () => {
    try {
      const response = await axios.get('https://api.mymeme.exchange/admin/deposits', {
        headers: { Authorization: `Bearer ${user.token}` }
      });
      setDeposits(response.data.deposits);
      setFilteredDeposits(response.data.deposits);
      setStats({
        totalDeposits: response.data.totalDeposits,
        newDeposits: response.data.newDeposits,
        totalAmount: response.data.totalAmount,
        pendingDeposits: response.data.pendingDeposits
      });
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch deposits');
      setLoading(false);
    }
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedDeposits = [...filteredDeposits].sort((a, b) => {
      if (key === 'amount') {
        return direction === 'ascending' ? a.amount - b.amount : b.amount - a.amount;
      } else if (key === 'createdAt') {
        return direction === 'ascending'
          ? new Date(a.createdAt) - new Date(b.createdAt)
          : new Date(b.createdAt) - new Date(a.createdAt);
      }
      return 0;
    });

    setFilteredDeposits(sortedDeposits);
  };

  if (!user || !user.token) {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="space-y-8">
      {/* First segment: 4 columns of cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Total Deposits</h2>
          <p className="text-3xl font-bold">{stats.totalDeposits}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">New Deposits (24H)</h2>
          <p className="text-3xl font-bold">{stats.newDeposits}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Total Amount</h2>
          <p className="text-3xl font-bold">${stats.totalAmount > 0 ? (stats.totalAmount / 100).toFixed(2) : '0.00'}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Pending Deposits</h2>
          <p className="text-3xl font-bold">{stats.pendingDeposits}</p>
        </div>
      </div>

      {/* Second segment: table of deposits */}
      {deposits && deposits.length > 0 && (
        <div>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Filter by Telegram Username"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="p-2 border rounded"
            />
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left">ID</th>
                  <th className="px-4 py-2 text-left">User</th>
                  <th className="px-4 py-2 text-left cursor-pointer" onClick={() => handleSort('amount')}>
                    Amount {sortConfig.key === 'amount' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                  </th>
                  <th className="px-4 py-2 text-left">Status</th>
                  <th className="px-4 py-2 text-left cursor-pointer" onClick={() => handleSort('createdAt')}>
                    Created At {sortConfig.key === 'createdAt' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredDeposits.map((deposit) => (
                  <tr key={deposit.id} onClick={() => navigate(`/meme/deposits/${deposit.id}`)} className="cursor-pointer hover:bg-gray-100">
                    <td className="border px-4 py-2">{deposit.id}</td>
                    <td className="border px-4 py-2">{deposit.user?.telegramUsername} ({deposit.userId})</td>
                    <td className="border px-4 py-2">${deposit.amount > 0 ? (deposit.amount / 100).toFixed(2) : '0.00'}</td>
                    <td className="border px-4 py-2">{deposit.status}</td>
                    <td className="border px-4 py-2">{new Date(deposit.createdAt).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}