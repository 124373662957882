import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Check localStorage for existing token on initial load
    const storedToken = localStorage.getItem('userToken');
    if (storedToken) {
      setUser({ token: storedToken });
    }
  }, []);

  const updateUser = (userData) => {
    if (userData && userData.token) {
      // Store the token in localStorage
      localStorage.setItem('userToken', userData.token);
      setUser(userData);
    } else {
      // If no token is provided, remove it from localStorage and set user to null
      localStorage.removeItem('userToken');
      setUser(null);
    }
  };

  return (
    <UserContext.Provider value={{ user, setUser: updateUser }}>
      {children}
    </UserContext.Provider>
  );
};