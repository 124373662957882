import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import axios from "axios";

export default function MemeCoin() {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [coin, setCoin] = useState(null);
  const [holdings, setHoldings] = useState(null);
  const [swaps, setSwaps] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user || !user.token) {
      navigate("/login");
    } else {
      fetchCoinDetails();
    }
  }, [user, navigate, id]);

  const fetchCoinDetails = async () => {
    try {
      const response = await axios.get(
        `https://api.mymeme.exchange/admin/coins/${id}`,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        },
      );
      setCoin(response.data.coin);
      setHoldings(response.data.holdings);
      setSwaps(response.data.swaps);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch coin details");
      setLoading(false);
    }
  };

  if (!user || !user.token) {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!coin) {
    return <div>No coin data available</div>;
  }

  return (
    <div className="space-y-8">
      <h1 className="text-2xl font-bold mb-4">{coin.name} ({coin.symbol})</h1>

      {/* Coin Details */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-4">Coin Summary</h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="font-semibold">ID</p>
            <p className="break-all">{coin.id}</p>
          </div>
          <div>
            <p className="font-semibold">Name</p>
            <p className="break-all">{coin.name}</p>
          </div>
          <div>
            <p className="font-semibold">Symbol</p>
            <p className="break-all">{coin.symbol}</p>
          </div>
          <div>
            <p className="font-semibold">Phase</p>
            <p className="break-all">{coin.phase}</p>
          </div>
          <div>
            <p className="font-semibold">Supply</p>
            <p className="break-all">{coin.supply}</p>
          </div>
          <div>
            <p className="font-semibold">Reserve</p>
            <p className="break-all">{coin.reserve}</p>
          </div>   
          <div>
            <p className="font-semibold">$</p>
            <p className="break-all">${(coin.realCent / 100).toFixed(2) || '0.00'}</p>
          </div>
          <div>
            <p className="font-semibold">Virtual $</p>
            <p className="break-all">${(coin.virtualCent / 100).toFixed(2) || '0.00'}</p>
          </div>             
          <div>
            <p className="font-semibold">Created At</p>
            <p className="break-all">{new Date(coin.createdAt).toLocaleString()}</p>
          </div>
          <div>
            <p className="font-semibold">Updated At</p>
            <p className="break-all">{new Date(coin.updatedAt).toLocaleString()}</p>
          </div>
          {/* Add more details as needed */}
        </div>
      </div>

      {/* Holdings Table */}
      {holdings && holdings.length > 0 && (
        <div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left">ID</th>
                  <th className="px-4 py-2 text-left">User</th>
                  <th className="px-4 py-2 text-left">Amount</th>
                  <th className="px-4 py-2 text-left">%</th>
                </tr>
              </thead>
              <tbody>
                {holdings.map((holding, index) => (
                  <tr key={index}>
                    <td className="border px-4 py-2">{index}</td>
                    <td className="border px-4 py-2">
                      {holding.user.telegramUsername} {holding.user.id}
                    </td>
                    <td className="border px-4 py-2">{holding.coinAmount}</td>
                    <td className="border px-4 py-2">
                      {((holding.coinAmount / 1_000_000_000) * 100).toFixed(2)}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Swaps Table */}
      {swaps && swaps.length > 0 && (
        <div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left">ID</th>
                  <th className="px-4 py-2 text-left">Nature</th>
                  <th className="px-4 py-2 text-left">Coin</th>
                  <th className="px-4 py-2 text-left">Cent</th>
                  <th className="px-4 py-2 text-left">User</th>
                  <th className="px-4 py-2 text-left">Timestamp</th>
                </tr>
              </thead>
              <tbody>
                {swaps.map((swap, index) => (
                  <tr key={index}>
                    <td className="border px-4 py-2">{swap.id}</td>
                    <td className="border px-4 py-2" style={{ color: swap.status === 'bought' ? 'green' : 'red' }}>{swap.status === 'bought' ? 'BUY' : 'SELL'}</td>
                    <td className="border px-4 py-2">{swap.coinAmount}</td>
                    <td className="border px-4 py-2">{swap.centAmount}</td>
                    <td className="border px-4 py-2">{swap.user.telegramUsername} ({swap.user.id})</td>
                    <td className="border px-4 py-2">{swap.amount}</td>
                    <td className="border px-4 py-2">
                      {new Date(swap.createdAt).toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
