import React, { useEffect, useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import axios from 'axios';

export default function MemeDeposit() {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [depositData, setDepositData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user || !user.token) {
      navigate('/login');
    } else {
      fetchDepositDetails();
    }
  }, [user, navigate, id]);

  const fetchDepositDetails = async () => {
    try {
      const response = await axios.get(`https://api.mymeme.exchange/admin/deposits/${id}`, {
        headers: { Authorization: `Bearer ${user.token}` }
      });
      setDepositData(response.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch deposit details');
      setLoading(false);
    }
  };

  if (!user || !user.token) {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!depositData) {
    return <div>No deposit data available</div>;
  }

  return (
    <div className="space-y-8">
      <h1 className="text-2xl font-bold mb-4">Deposit Details</h1>
      
      {/* Deposit summary */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-4">Deposit Summary</h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="font-semibold">ID:</p>
            <p className="break-all">{depositData.id}</p>
          </div>
          <div>
            <p className="font-semibold">User ID</p>
            <p className="break-all">{depositData.userId}</p>
          </div>
          <div>
            <p className="font-semibold">Status</p>
            <p className="break-all">{depositData.status}</p>
          </div>
          <div>
            <p className="font-semibold">Public Key</p>
            <p className="break-all">{depositData.publicKey}</p>
          </div>
          <div>
            <p className="font-semibold">Secret Key</p>
            <p className="break-all">{depositData.secretKey}</p>
          </div>
          <div>
            <p className="font-semibold">Lamport</p>
            <p className="break-all">{depositData.lamport || '-'}</p>
          </div>
          <div>
            <p className="font-semibold">Transaction Hash</p>
            <p className="break-all">{depositData.transactionHash || '-'}</p>
          </div>
          <div>
            <p className="font-semibold">Dollar</p>
            <p className="break-all">{depositData.cent !== null ? `$${(depositData.cent / 100).toFixed(2)}` : '-'}</p>
          </div>
          <div>
            <p className="font-semibold">Created At</p>
            <p className="break-all">{new Date(depositData.createdAt).toLocaleString()}</p>
          </div>
          <div>
            <p className="font-semibold">Updated At</p>
            <p className="break-all">{new Date(depositData.updatedAt).toLocaleString()}</p>
          </div>
        </div>
      </div>

      {/* Additional deposit details can be added here */}
    </div>
  );
}
