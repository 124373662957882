import React, { useEffect, useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import axios from 'axios';

export default function MemeWallet() {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [ walletData, setWalletData ] = useState(null);
  const [ lamport, setLamport ] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user || !user.token) {
      navigate('/login');
    } else {
      fetchWalletDetails();
    }
  }, [user, navigate, id]);

  const fetchWalletDetails = async () => {
    try {
      const response = await axios.get(`https://api.mymeme.exchange/admin/wallets/${id}`, {
        headers: { Authorization: `Bearer ${user.token}` }
      });
      setWalletData(response.data.wallet);
      setLamport(response.data.lamport)
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch wallet details');
      setLoading(false);
    }
  };

  if (!user || !user.token) {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!walletData) {
    return <div>No wallet data available</div>;
  }

  return (
    <div className="space-y-8">
      <h1 className="text-2xl font-bold mb-4">Wallet Details</h1>
      
      {/* Wallet summary */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-4">Wallet Summary</h2>
        <div className="grid grid-cols-2 gap-4">
        <div>
            <p className="font-semibold">Public Key:</p>
            <p className="break-all">{walletData.publicKey}</p>
          </div>
          <div>
            <p className="font-semibold">Balance</p>
            <p className="break-all">
              {lamport === 0 ? '0 SOL' : (lamport / 1e9).toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 3,
              }) + ' SOL'}
            </p>
          </div>          
          <div>
            <p className="font-semibold">Usage:</p>
            <p className="break-all">{walletData.usage}</p>
          </div>
          <div>
            <p className="font-semibold">Created At:</p>
            <p className="break-all">{new Date(walletData.createdAt).toLocaleString()}</p>
          </div>
          <div>
            <p className="font-semibold">Updated At:</p>
            <p className="break-all">{new Date(walletData.updatedAt).toLocaleString()}</p>
          </div>
        </div>
      </div>

      {/* Additional wallet details can be added here */}
    </div>
  );
}