import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import axios from "axios";

export default function MemeDashboard() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [dashboard, setDashboard] = useState({
    profitWithdrawals: [],
    newDeposits: 0,
    totalAmount: 0,
    pendingDeposits: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchDashboard = useCallback(async () => {
    if (!user || !user.token) return;
    try {
      const response = await axios.get(
        "https://api.mymeme.exchange/admin/dashboard",
        {
          headers: { Authorization: `Bearer ${user.token}` },
        },
      );
      setDashboard(response.data);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch dashboard data");
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (!user || !user.token) {
      navigate("/login");
    } else {
      fetchDashboard();
    }
  }, [user, navigate, fetchDashboard]);

  if (!user || !user.token) {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="space-y-8">
      <h1 className="text-2xl font-bold mb-4">Dashboard</h1>

    </div>
  );
}
