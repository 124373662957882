import React, { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import axios from "axios";

export default function MemePremarkManagers() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [managers, setManagers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newManager, setNewManager] = useState({
    name: '',
    description: '',
  });
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (!user || !user.token) {
      navigate("/login");
    } else {
      fetchManagers();
    }
  }, [user, navigate]);

  const fetchManagers = async () => {
    try {
      const response = await axios.get(
        "https://api.mymeme.exchange/admin/premark-managers",
        {
          headers: { Authorization: `Bearer ${user.token}` },
        },
      );
      const managers = response.data;
      console.log(response.data);
      setManagers(managers);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch managers");
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewManager(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        "https://api.mymeme.exchange/admin/premark-managers",
        newManager,
        {
          headers: { 
            Authorization: `Bearer ${user.token}`,
            'Content-Type': 'application/json'
          },
        }
      );

      if (response) {
        fetchManagers();
        setNewManager({
          name: '',
          description: '',
        });
      }

      setLoading(false);
    } catch (err) {
      setError("Failed to create new manager");
      setLoading(false);
    }
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedManagers = useMemo(() => {
    let sortableItems = [...managers];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [managers, sortConfig]);

  const filteredManagers = useMemo(() => {
    return sortedManagers.filter(manager =>
      manager.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [sortedManagers, searchTerm]);  

  if (!user || !user.token) {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Total Managers</h2>
          <p className="text-3xl font-bold">{managers.length}</p>
        </div>
        {/* <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Active Managers</h2>
          <p className="text-3xl font-bold">{managers.filter(m => m.status === 'active').length}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Inactive Managers</h2>
          <p className="text-3xl font-bold">{managers.filter(m => m.status === 'inactive').length}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Admin Managers</h2>
          <p className="text-3xl font-bold">{managers.filter(m => m.role === 'admin').length}</p>
        </div> */}
      </div>

     {managers && managers.length > 0 && (
        <div>
          <input
            type="text"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mb-4 p-2 border rounded"
          />
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left">
                    ID
                  </th>                  
                  <th className="px-4 py-2 text-left cursor-pointer" onClick={() => requestSort('name')}>
                    Name {sortConfig.key === 'name' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                  </th>
                  <th className="px-4 py-2 text-left cursor-pointer" onClick={() => requestSort('description')}>
                    Description {sortConfig.key === 'description' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                  </th>

                </tr>
              </thead>
              <tbody>
                {filteredManagers.map((manager) => (
                  <tr key={manager.id} onClick={() => navigate(`/meme/premark-managers/${manager.id}`)} className="cursor-pointer hover:bg-gray-100">
                    <td className="border px-4 py-2">{manager.id}</td>
                    <td className="border px-4 py-2">{manager.name}</td>
                    <td className="border px-4 py-2">{manager.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-4">Create New Manager</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={newManager.name}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
            <input
              type="text"
              id="description"
              name="description"
              value={newManager.description}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>

          <div>
            <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Create Manager
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}