import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import axios from "axios";

export default function MemeWallets() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [wallets, setWallets] = useState([]);
  const [newWallet, setNewWallet] = useState({
    usage: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({
    totalWallets: 0,
  });

  useEffect(() => {
    if (!user || !user.token) {
      navigate("/login");
    } else {
      fetchWallets();
    }
  }, [user, navigate]);

  const fetchWallets = async () => {
    try {
      const response = await axios.get(
        "https://api.mymeme.exchange/admin/wallets",
        {
          headers: { Authorization: `Bearer ${user.token}` },
        },
      );
      console.log(response.data);
      setWallets(response.data);
      setStats({
        totalWallets: response.data.totalWallets,
      });
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch wallets");
      setLoading(false);
    }
  };

  const handleCreateWallet = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      Object.keys(newWallet).forEach((key) => {
        formData.append(key, newWallet[key]);
      });

      const response = await axios.post(
        "https://api.mymeme.exchange/admin/wallets",
        formData,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "multipart/form-data",
          },
        },
      );
      console.log("New wallet created:", response.data);
      setNewWallet({ usage: "" }); // Reset the form
      fetchWallets();
      setLoading(false);
    } catch (err) {
      console.error("Failed to create wallet:", err);
      setError("Failed to create wallet");
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewWallet((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  if (!user || !user.token) {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="space-y-8">
      {/* First segment: 4 columns of cards */}
      {
        /* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Total Wallets</h2>
          <p className="text-3xl font-bold">{stats.totalWallets}</p>
        </div>
      </div> */
      }

      <div className="flex">
        <div className="w-1/3 pr-4">
          <div className="w-full bg-white shadow-lg rounded-lg p-6">
            <form onSubmit={handleCreateWallet} className="space-y-6">
              <div>
                <label
                  htmlFor="usage"
                  className="block text-sm font-semibold text-gray-700 mb-1"
                >
                  Usage
                </label>
                <input
                  type="text"
                  id="usage"
                  name="usage"
                  value={newWallet.usage}
                  onChange={handleInputChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out"
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
                >
                  Create Wallet
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="w-2/3 pl-4">
          {wallets && wallets.length > 0 && (
            <div>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 text-left">ID</th>
                      <th className="px-4 py-2 text-left">Usage</th>
                      <th className="px-4 py-2 text-left">Balance</th>
                      <th className="px-4 py-2 text-left">Public Key</th>
                    </tr>
                  </thead>
                  <tbody>
                    {wallets.map((wallet) => (
                      <tr
                        key={wallet.id}
                        onClick={() => navigate(`/meme/wallets/${wallet.id}`)}
                        className="cursor-pointer hover:bg-gray-100"
                      >
                        <td className="border px-4 py-2">{wallet.id}</td>
                        <td className="border px-4 py-2">{wallet.usage}</td>
                        <td className="border px-4 py-2">
                          {wallet.balance
                            ? Number(wallet.balance).toLocaleString("en-US", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 3,
                            })
                            : "-"}
                        </td>
                        <td className="border px-4 py-2">{wallet.publicKey}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
