import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import axios from "axios";

export default function MemePost() {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user || !user.token) {
      navigate("/login");
    } else {
      fetchPostDetails();
    }
  }, [user, navigate, id]);

  const fetchPostDetails = async () => {
    try {
      const response = await axios.get(
        `https://api.mymeme.exchange/admin/posts/${id}`,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        },
      );
      setPost(response.data);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch post details");
      setLoading(false);
    }
  };

  if (!user || !user.token) {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!post) {
    return <div>No post data available</div>;
  }

  return (
    <div className="space-y-8">
      <h1 className="text-2xl font-bold mb-4">Post Details</h1>

      {/* Post Details */}

      <h2 className="text-xl font-semibold mb-4">Post Summary</h2>
      <div className="flex">
        <div className="w-1/3 pr-4">
          {post.imageUrl && (
            <img
              src={post.imageUrl}
              alt="Post"
              className="w-full h-auto rounded-lg"
            />
          )}
        </div>
        <div className="w-2/3 pl-4bg-white p-6 rounded-lg shadow">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="font-semibold">ID</p>
              <p className="break-all">{post.id}</p>
            </div>
            <div>
              <p className="font-semibold">Character ID</p>
              <p className="break-all">{post.characterId}</p>
            </div>
            <div>
              <p className="font-semibold">Content</p>
              <p className="break-all">{post.text}</p>
            </div>
            <div>
              <p className="font-semibold">Status</p>
              <p className="break-all">{post.status}</p>
            </div>
            <div>
              <p className="font-semibold">Created At</p>
              <p className="break-all">
                {new Date(post.createdAt).toLocaleString()}
              </p>
            </div>
            <div>
              <p className="font-semibold">Updated At</p>
              <p className="break-all">
                {new Date(post.updatedAt).toLocaleString()}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
