import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import axios from "axios";

export default function InternDetails() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [intern, setIntern] = useState(null);
  const [internUser, setUser] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user || !user.token) {
      navigate("/login");
    } else {
      fetchInternDetails();
    }
  }, [user, navigate, id]);

  const fetchInternDetails = async () => {
    try {
      const response = await axios.get(
        `https://api.mymeme.exchange/admin/interns/${id}`,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        },
      );
      setIntern(response.data.intern);
      setUser(response.data.user);
      setTasks(response.data.tasks);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch intern details");
      setLoading(false);
    }
  };

  if (!user || !user.token) {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="space-y-8">
      <h1 className="text-2xl font-bold">Intern Details</h1>

      {/* Intern Summary */}
      {intern && (
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Intern Summary</h2>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="font-semibold">ID</p>
              <p className="break-all">{intern.id}</p>
            </div>
            <div>
              <p className="font-semibold">User ID</p>
              <p className="break-all">{internUser.id}</p>
            </div>            
            <div>
              <p className="font-semibold">Twitter Username</p>
              <p className="break-all">{intern.twitterUsername}</p>
            </div>
            <div>
              <p className="font-semibold">Telegram Username</p>
              <p className="break-all">{internUser.telegramUsername}</p>
            </div>  
            <div>
              <p className="font-semibold">Balance</p>
              <p className="break-all">${internUser.centBalance > 0 ? (internUser.centBalance / 100).toFixed(2) : '0.00'}</p>
            </div>                      
            <div>
              <p className="font-semibold">MEME Balance</p>
              <p className="break-all">{intern.memeBalance}</p>
            </div>
            <div>
              <p className="font-semibold">Created At</p>
              <p className="break-all">
                {new Date(intern.createdAt).toLocaleString()}
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Tasks List */}

      {tasks && tasks.length > 0 && (
        <div className="bg-white p-6 rounded-lg shadow">
          <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-4 py-2 text-left">Task</th>
                  <th className="px-4 py-2 text-left">Status</th>
                  <th className="px-4 py-2 text-left">Reward</th>
                </tr>
              </thead>
              <tbody>
                {tasks.map((task) => (
                  <tr key={task.id} className="border-b">
                    <td className="px-4 py-2">{task.assignment.name}</td>
                    <td className="px-4 py-2">{task.status}</td>
                    <td className="px-4 py-2">{task.reward}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

    </div>
  );
}
