import React from 'react';
import { Link } from 'react-router-dom';

function MemeLayout({ children }) {
  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="bg-white shadow-lg">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex justify-between">
            <div className="flex space-x-7">
              <div>
                <Link to="/meme/dashboard" className="flex items-center py-4 px-2">
                  <span className="font-semibold text-gray-500 text-lg">Manager</span>
                </Link>
              </div>
              <div className="hidden md:flex items-center space-x-1">
                <Link to="/meme/coins" className="py-4 px-2 text-gray-500 hover:text-gray-900">Coin</Link>
                <Link to="/meme/characters" className="py-4 px-2 text-gray-500 hover:text-gray-900">Character</Link>
                <Link to="/meme/internship" className="py-4 px-2 text-gray-500 hover:text-gray-900">Internship</Link>              
                <Link to="/meme/premarks" className="py-4 px-2 text-gray-500 hover:text-gray-900">Premark</Link>              
                <Link to="/meme/deposits" className="py-4 px-2 text-gray-500 hover:text-gray-900">Deposit</Link>
                <Link to="/meme/withdrawals" className="py-4 px-2 text-gray-500 hover:text-gray-900">Withdrawal</Link>
                <Link to="/meme/wallets" className="py-4 px-2 text-gray-500 hover:text-gray-900">Wallet</Link>
                <Link to="/meme/users" className="py-4 px-2 text-gray-500 hover:text-gray-900">User</Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <main className="max-w-6xl mx-auto mt-6 px-4">
        {children}
      </main>
    </div>
  );
}

export default MemeLayout;