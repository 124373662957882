import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import axios from "axios";

export default function MemeUser() {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [holdings, setHoldings] = useState([]);
  const [solanaPayments, setSolanaPayments] = useState([]);
  const [solanaWithdrawals, setSolanaWithdrawals] = useState([]);
  const [swaps, setSwaps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user || !user.token) {
      navigate("/login");
    } else {
      fetchUserDetails();
    }
  }, [user, navigate, id]);

  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(
        `https://api.mymeme.exchange/admin/users/${id}`,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        },
      );
      console.log(response.data);
      setUserData(response.data.user);
      setHoldings(response.data.holdings || []);
      setSwaps(response.data.swaps || []);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch user details");
      setLoading(false);
    }
  };

  if (!user || !user.token) {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!userData) {
    return <div>No user data available</div>;
  }

  return (
    <div className="space-y-8">
      <h1 className="text-2xl font-bold mb-4">User Details</h1>

      {/* First segment: User summary */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-4">User Summary</h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="font-semibold">ID</p>
            <p className="break-all">{userData.id}</p>
          </div>
          <div>
            <p className="font-semibold">Telegram Username</p>
            <p className="break-all">{userData.telegramUsername}</p>
          </div>
          <div>
            <p className="font-semibold">Telegram ID</p>
            <p className="break-all">{userData.telegramId}</p>
          </div>
          <div>
            <p className="font-semibold">Balance</p>
            <p className="break-all">${userData.centBalance > 0 ? (userData.centBalance / 100).toFixed(2) : '0.00'}</p>
          </div>
          <div>
            <p className="font-semibold">Total Swaps</p>
            <p className="break-all">{swaps ? swaps.length : 0}</p>
          </div>
          <div>
            <p className="font-semibold">Total Holdings</p>
            <p className="break-all">{holdings ? holdings.length : 0}</p>
          </div>
          <div>
            <p className="font-semibold">Deposits</p>
            <p className="break-all">{userData.solanaPayments ? userData.solanaPayments.length : 0}</p>
          </div>
          <div>
            <p className="font-semibold">Withdrawals</p>
            <p className="break-all">{userData.solanaWithdrawals ? userData.solanaWithdrawals.length : 0}</p>
          </div>
          <div>
            <p className="font-semibold">Updated At</p>
            <p className="break-all">{new Date(userData.updatedAt).toLocaleString()}</p>
          </div>
          <div>
            <p className="font-semibold">Created At</p>
            <p className="break-all">{new Date(userData.createdAt).toLocaleString()}</p>
          </div>
        </div>
      </div>

      {holdings && holdings.length > 0 && (
        <div>
          <h2 className="text-xl font-semibold mb-2">Holdings</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left">Coin</th>
                  <th className="px-4 py-2 text-left">Amount</th>
                  <th className="px-4 py-2 text-left">%</th>
                  <th className="px-4 py-2 text-left">Updated</th>
                </tr>
              </thead>
              <tbody>
                {holdings.map((holding, index) => (
                  <tr key={index} onClick={() => navigate(`/meme/coins/${holding.coin.id}`)} className="cursor-pointer hover:bg-gray-100">
                    <td className="border px-4 py-2">{holding.coin.name}</td>
                    <td className="border px-4 py-2">{holding.coinAmount}</td>
                    <td className="border px-4 py-2">{holding.coinAmount > 0 ? ((holding.coinAmount / 1000000000) * 100).toFixed(2) + "%" : "0.00%"}</td>
                    <td className="border px-4 py-2">
                      {new Date(holding.updatedAt).toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {swaps && swaps.length > 0 && (
        <div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left">From Coin</th>
                  <th className="px-4 py-2 text-left">To Coin</th>
                  <th className="px-4 py-2 text-left">Amount</th>
                  <th className="px-4 py-2 text-left">Date</th>
                </tr>
              </thead>
              <tbody>
                {swaps.map((swap, index) => (
                  <tr key={index}>
                    <td className="border px-4 py-2">{swap.fromCoinSymbol}</td>
                    <td className="border px-4 py-2">{swap.toCoinSymbol}</td>
                    <td className="border px-4 py-2">{swap.amount}</td>
                    <td className="border px-4 py-2">
                      {new Date(swap.createdAt).toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {solanaPayments && solanaPayments.length > 0 && (
        <div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left">From Coin</th>
                  <th className="px-4 py-2 text-left">To Coin</th>
                  <th className="px-4 py-2 text-left">Amount</th>
                  <th className="px-4 py-2 text-left">Date</th>
                </tr>
              </thead>
              <tbody>
                {solanaPayments.map((solanaPayment, index) => (
                  <tr key={index}>
                    <td className="border px-4 py-2">
                      {solanaPayment.fromCoinSymbol}
                    </td>
                    <td className="border px-4 py-2">
                      {solanaPayment.toCoinSymbol}
                    </td>
                    <td className="border px-4 py-2">{solanaPayment.amount}</td>
                    <td className="border px-4 py-2">
                      {new Date(solanaPayment.createdAt).toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {solanaWithdrawals && solanaWithdrawals.length > 0 && (
        <div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left">From Coin</th>
                  <th className="px-4 py-2 text-left">To Coin</th>
                  <th className="px-4 py-2 text-left">Amount</th>
                  <th className="px-4 py-2 text-left">Date</th>
                </tr>
              </thead>
              <tbody>
                {solanaWithdrawals.map((solanaWithdrawal, index) => (
                  <tr key={index}>
                    <td className="border px-4 py-2">
                      {solanaWithdrawal.fromCoinSymbol}
                    </td>
                    <td className="border px-4 py-2">
                      {solanaWithdrawal.toCoinSymbol}
                    </td>
                    <td className="border px-4 py-2">
                      {solanaWithdrawal.amount}
                    </td>
                    <td className="border px-4 py-2">
                      {new Date(solanaWithdrawal.createdAt).toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
