import React, { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import axios from "axios";


export default function MemeCharacters() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [characters, setCharacters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({
    totalCharacters: 0,
    totalCoins: 0,
    totalPosts: 0,
  });  
  const [newCharacter, setNewCharacter] = useState({
    name: '',
    description: '',
    newsKeyword: '',
    image: null
  });
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (!user || !user.token) {
      navigate("/login");
    } else {
      fetchCharacters();
    }
  }, [user, navigate]);

  const fetchCharacters = async () => {
    try {
      const response = await axios.get(
        "https://api.mymeme.exchange/admin/characters",
        {
          headers: { Authorization: `Bearer ${user.token}` },
        },
      );
      console.log(response.data);
      setCharacters(response.data.characters);
      setStats({
        totalCharacters: response.data.totalCharacters,
        totalCoins: response.data.totalCoins,
        totalPosts: response.data.totalPosts,
      })
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch characters");
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setNewCharacter(prev => ({
      ...prev,
      [name]: files ? files[0] : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      Object.keys(newCharacter).forEach(key => {
        formData.append(key, newCharacter[key]);
      });

      const response = await axios.post(
        "https://api.mymeme.exchange/admin/characters",
        formData,
        {
          headers: { 
            Authorization: `Bearer ${user.token}`,
            'Content-Type': 'multipart/form-data'
          },
        }
      );

      if (response) {
        fetchCharacters();
        setNewCharacter({
          name: '',
          description: '',
          newsKeyword: '',
          image: null
        });
      }

      setLoading(false);
    } catch (err) {
      setError("Failed to create new character");
      setLoading(false);
    }
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };


  const sortedCharacters = useMemo(() => {
    if (!Array.isArray(characters)) return [];
    let sortableItems = [...characters];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [characters, sortConfig]);
  
  const filteredCharacters = useMemo(() => {
    if (!Array.isArray(sortedCharacters)) return [];
    return sortedCharacters.filter(character =>
      character.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [sortedCharacters, searchTerm]); 

  if (!user || !user.token) {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="space-y-8">
      {/* First segment: 4 columns of cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Total Character</h2>
          <p className="text-3xl font-bold">{stats.totalCharacters}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Total Post</h2>
          <p className="text-3xl font-bold">{stats.totalPosts}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Total Coin</h2>
          <p className="text-3xl font-bold">{stats.totalCoins}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">-</h2>
          <p className="text-3xl font-bold">-</p>
        </div>
      </div>

     <div className="flex">
       <div className="w-1/3 pr-4">
         <div className="w-full bg-white shadow-lg rounded-lg p-6">
           <form onSubmit={handleSubmit} className="space-y-6">
             <div>
               <label htmlFor="name" className="block text-sm font-semibold text-gray-700 mb-1">Name</label>
               <input
                 type="text"
                 id="name"
                 name="name"
                 value={newCharacter.name}
                 onChange={handleInputChange}
                 required
                 className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out"
               />
             </div>
             <div>
               <label htmlFor="newsKeyword" className="block text-sm font-semibold text-gray-700 mb-1">News Keyword</label>
               <input
                 type="text"
                 id="newsKeyword"
                 name="newsKeyword"
                 value={newCharacter.newsKeyword}
                 onChange={handleInputChange}
                 required
                 className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out"
               />
             </div>             
             <div>
               <label htmlFor="description" className="block text-sm font-semibold text-gray-700 mb-1">Description</label>
               <textarea
                 id="description"
                 name="description"
                 value={newCharacter.description}
                 onChange={handleInputChange}
                 rows="4"
                 className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out resize-none"
               ></textarea>
             </div>
             <div>
               <label htmlFor="image" className="block text-sm font-semibold text-gray-700 mb-1">Image</label>
               <input
                 type="file"
                 id="image"
                 name="image"
                 onChange={handleInputChange}
                 accept="image/*"
                 required
                 className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
               />
             </div>
             <div>
               <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out">
                 Create Character
               </button>
             </div>
           </form>
         </div>
       </div>

       <div className="w-2/3 pl-4">
         {characters && characters.length > 0 && (
           <div>
             <input
               type="text"
               placeholder="Search by name..."
               value={searchTerm}
               onChange={(e) => setSearchTerm(e.target.value)}
               className="mb-4 p-2 border rounded w-full"
             />
             <div className="overflow-x-auto">
               <table className="min-w-full bg-white">
                 <thead>
                   <tr>
                   <th className="px-4 py-2 text-left">
                       ID
                     </th>                  
                     <th className="px-4 py-2 text-left cursor-pointer" onClick={() => requestSort('name')}>
                       Name {sortConfig.key === 'name' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                     </th>
                     <th className="px-4 py-2 text-left cursor-pointer" onClick={() => requestSort('postCount')}>
                       No. of Post {sortConfig.key === 'postCount' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                     </th>
                     <th className="px-4 py-2 text-left cursor-pointer" onClick={() => requestSort('coinCount')}>
                       No. of Coin {sortConfig.key === 'coinCount' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                     </th>
                   </tr>
                 </thead>
                 <tbody>
                   {filteredCharacters.map((character) => (
                     <tr key={character.id} onClick={() => navigate(`/meme/characters/${character.id}`)} className="cursor-pointer hover:bg-gray-100">
                       <td className="border px-4 py-2">{character.id}</td>
                       <td className="border px-4 py-2">{character.name}</td>
                       <td className="border px-4 py-2">{character.postCount}</td>
                       <td className="border px-4 py-2">{character.coinCount}</td>
                     </tr>
                   ))}
                 </tbody>
               </table>
             </div>
           </div>
         )}
       </div>
     </div>
    </div>
  );
}