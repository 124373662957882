import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import axios from "axios";

export default function MemePremarkManager() {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [manager, setManager] = useState(null);
  const [coins, setCoins] = useState(null);
  const [posts, setPosts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("about");

  const fetchManagerDetails = useCallback(async () => {
    if (!user || !user.token) return;

    try {
      const response = await axios.get(
        `https://api.mymeme.exchange/admin/managers/${id}`,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        },
      );
      setManager(response.data.manager);
      setCoins(response.data.coins);
      setPosts(response.data.posts);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch manager details");
      setLoading(false);
    }
  }, [id, user]);

  useEffect(() => {
    if (!user || !user.token) {
      navigate("/login");
    } else {
      fetchManagerDetails();
    }
  }, [user, navigate, fetchManagerDetails]);

  if (!user || !user.token) {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!manager) {
    return <div>No manager data available</div>;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setManager((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdateManager = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.put(
        `https://api.mymeme.exchange/admin/managers/${manager.id}`,
        {
          name: manager.name,
          description: manager.description,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        },
      );

      if (response.data) {
        fetchManagerDetails();
      }

      setLoading(false);
    } catch (err) {
      setError("Failed to update manager");
      setLoading(false);
    }
  };

  return (
    <div className="space-y-8">
      <h1 className="text-2xl font-bold mb-4">
        {manager.name} 
      </h1>

      <div className="flex flex-col space-y-8">
        <div className="flex flex-row space-x-4">
          <button
            className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-medium py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-200"
            onClick={() => setActiveTab("about")}
          >
            About
          </button>
          <button
            className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-medium py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-200"
            onClick={() => setActiveTab("post")}
          >
            Post
          </button>
          <button
            className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-medium py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-200"
            onClick={() => setActiveTab("coin")}
          >
            Coin
          </button>
        </div>
        <div>
          {activeTab === "about" && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="w-full md:w-1/2 bg-white shadow-lg rounded-lg p-6">
                <form onSubmit={handleUpdateManager} className="space-y-6">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-semibold text-gray-700 mb-1"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={manager.name}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="description"
                      className="block text-sm font-semibold text-gray-700 mb-1"
                    >
                      Description
                    </label>
                    <textarea
                      id="description"
                      name="description"
                      value={manager.description}
                      onChange={handleInputChange}
                      rows="4"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition duration-150 ease-in-out resize-none"
                    ></textarea>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
                    >
                      Update Manager
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
          {activeTab === "post" && (
            <div>
              {posts && posts.length > 0 && (
                <div className="overflow-x-auto">
                  <table className="min-w-full bg-white">
                    <thead>
                      <tr>
                        <th className="px-4 py-2 text-left">ID</th>
                        <th className="px-4 py-2 text-left">Content</th>
                        <th className="px-4 py-2 text-left">Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {posts.map((post) => (
                        <tr key={post.id}>
                          <td className="border px-4 py-2">{post.id}</td>
                          <td className="border px-4 py-2">{post.content}</td>
                          <td className="border px-4 py-2">
                            {new Date(post.createdAt).toLocaleString()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
          {activeTab === "coin" && (
            <div>
              {coins && coins.length > 0 && (
                <div className="overflow-x-auto">
                  <table className="min-w-full bg-white">
                    <thead>
                      <tr>
                        <th className="px-4 py-2 text-left">ID</th>
                        <th className="px-4 py-2 text-left">Name</th>
                        <th className="px-4 py-2 text-left">Reserve</th>
                        <th className="px-4 py-2 text-left">Progress</th>
                        <th className="px-4 py-2 text-left">Phase</th>
                        <th className="px-4 py-2 text-left">Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {coins.map((coin) => (
                        <tr
                          key={coin.id}
                          onClick={() => navigate(`/meme/coins/${coin.id}`)}
                          className="cursor-pointer hover:bg-gray-100"
                        >
                          <td className="border px-4 py-2">{coin.id}</td>
                          <td className="border px-4 py-2">{coin.name}</td>
                          <td className="border px-4 py-2">{coin.reserve}</td>
                          <td className="border px-4 py-2">
                            {coin.supply > 0
                              ? ((coin.supply / 1000000000) * 100).toFixed(2) +
                                "%"
                              : "0.00%"}
                          </td>
                          <td className="border px-4 py-2">{coin.phase}</td>
                          <td className="border px-4 py-2">
                            {new Date(coin.createdAt).toLocaleString()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}