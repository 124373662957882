import React, { useEffect, useContext, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import axios from 'axios';

export default function MemeCoins() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [coins, setCoins] = useState([]);
  const [moonshots, setMoonshots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // New state variables
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('id');
  const [sortDirection, setSortDirection] = useState('asc');
  const [currentPage, setCurrentPage] = useState(0);
  const coinsPerPage = 10;

  useEffect(() => {
    if (!user || !user.token) {
      navigate('/login');
    } else {
      fetchCoins();
    }
  }, [user, navigate]);

  const fetchCoins = async () => {
    try {
      const response = await axios.get('https://api.mymeme.exchange/admin/coins', {
        headers: { Authorization: `Bearer ${user.token}` }
      });
      const { coins, moonshots } = response.data;
      setCoins(coins);
      setMoonshots(moonshots);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch coins');
      setLoading(false);
    }
  };

  const filteredCoins = useMemo(() => {
    return coins
      .filter(coin => 
        coin.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        coin.symbol.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => {
        if (a[sortBy] < b[sortBy]) return sortDirection === 'asc' ? -1 : 1;
        if (a[sortBy] > b[sortBy]) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
  }, [coins, searchTerm, sortBy, sortDirection]);

  if (!user || !user.token) {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="space-y-8">
      {/* First segment: 4 columns of cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Total</h2>
          <p className="text-3xl font-bold">{coins.length}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Moonshot</h2>
          <p className="text-3xl font-bold">{moonshots.length}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">Dead</h2>
          <p className="text-3xl font-bold">{coins.filter(coin => coin.status === 'dead').length}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold">New</h2>
          <p className="text-3xl font-bold">{coins.filter(coin => coin.status === 'new').length}</p>
        </div>
      </div>

      {/* Second segment: search, sort, and table */}
      <div>
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <input
              type="text"
              className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Search..."
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="flex items-center space-x-4">
            <select
              className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
            >
              <option value="id">ID</option>
              <option value="name">Name</option>
              <option value="reserve">Reserve</option>
              <option value="supply">Progress</option>
              <option value="status">Status</option>
            </select>
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              onClick={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
            >
              {sortDirection === 'asc' ? 'Descending' : 'Ascending'}
            </button>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left">ID</th>
                <th className="px-4 py-2 text-left">Name</th>
                <th className="px-4 py-2 text-left">Reserve</th>
                <th className="px-4 py-2 text-left">Progress</th>
                <th className="px-4 py-2 text-left">Phase</th>
                <th className="px-4 py-2 text-left">Creator</th>
              </tr>
            </thead>
            <tbody>
              {filteredCoins.slice(currentPage * coinsPerPage, (currentPage + 1) * coinsPerPage).map((coin) => (
                <tr key={coin.id} onClick={() => navigate(`/meme/coins/${coin.id}`)}>
                  <td className="border px-4 py-2">{coin.id}</td>
                  <td className="border px-4 py-2">{coin.name} ({coin.symbol})</td>
                  <td className="border px-4 py-2">{coin.reserve}</td>
                  <td className="border px-4 py-2">{coin.supply > 0 ? ((coin.supply / 1000000000) * 100).toFixed(2) + "%" : "0.00%"}</td>
                  <td className="border px-4 py-2">{coin.phase}</td>
                  <td className="border px-4 py-2">{coin.userId ? `@${coin.user.telegramUsername}` : coin.character.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-4">
          <button
            className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 0}
          >
            Previous
          </button>
          <span className="mx-2">{currentPage + 1} of {Math.ceil(filteredCoins.length / coinsPerPage)}</span>
          <button
            className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === Math.ceil(filteredCoins.length / coinsPerPage) - 1}
          >
            Next
          </button>
        </div>
      </div>

      {/* Third segment: table of moonshots */}
      {moonshots && moonshots.length > 0 && (
        <div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left">Name</th>
                  <th className="px-4 py-2 text-left">Symbol</th>
                  <th className="px-4 py-2 text-left">Potential</th>
                </tr>
              </thead>
              <tbody>
                {moonshots.map((moonshot) => (
                  <tr key={moonshot.id}>
                    <td className="border px-4 py-2">{moonshot.name}</td>
                    <td className="border px-4 py-2">{moonshot.symbol}</td>
                    <td className="border px-4 py-2">{moonshot.potential}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      
    </div>
  );
}